import React from 'react';
import './404.scss';

function NOTFOUND() {

    return (
        <section id="not-found">
            <h1>404 Not Found</h1>

        </section>
    )

}
export default NOTFOUND;